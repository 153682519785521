const Login = (() => {
    function getCookie(cname) {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length).slice(1, -1).replace(/\\/g, '');
            }
        }
        return '';
    }

    const updateMyBfUrls = (sso) => {
        const objectMyBf = $('.js-check-mybf-url');
        objectMyBf.each((item) => {
            const urlMyBf = objectMyBf.eq(item).attr('href');
            if (urlMyBf.indexOf('my.basic-fit.com') !== -1) {
                const extraParam = urlMyBf.split('.com/').pop();
                if (extraParam) {
                    const splittedUrl = urlMyBf.split('.com/');
                    objectMyBf.eq(item).attr('href', `${splittedUrl[0]}.com/${sso}&returl=/${splittedUrl[1]}`);
                } else {
                    objectMyBf.eq(item).attr('href', urlMyBf + sso);
                }
            }
        });
    };

    const showLoggedUser = () => {
        let token = null;
        let sso = null;
        let firstName = null;
        let clubId = null;
        const cookieString = getCookie('mybf');
        if (cookieString) {
            const myBfCookie = JSON.parse(cookieString);
            token = myBfCookie.accessToken;
            sso = myBfCookie.ssoUrl;
            firstName = myBfCookie.firstName;
            clubId = myBfCookie.homeClub.id.replace(/-/g, '').toLowerCase();
            $('.js-homeclub-url').attr('href', `${$('.js-homeclub-url').attr('href')}?pid=${clubId}`);
        }

        if (token && sso && firstName) {
            $('.js-service').hide();
            $('.js-service-mybf').show();
            $('.js-homeclub').show();
            $('.js-login').hide();
            $('.js-create-account').hide();
            $('.js-login-url').hide();
            $('.js-become-member').hide();
            $('.js-prices').hide();
            $('.js-whybasicfit').hide();
            $('.js-logged').css('display', 'inline-block');
            $('.js-logout-url').css('display', 'block');
            $('.js-welcome').css('display', 'inline-block');
            $('.js-mybf-name').html(firstName);
            updateMyBfUrls(sso);
        } else {
            $('.js-logged').hide();
            $('.js-logout-url').hide();
            $('.js-welcome').hide();
            $('.js-login').css('display', 'inline-block');
            $('.js-become-member').css('display', 'inline-block');
        }
    };

    const handleResponse = (response) => {
        if (response.accessToken) {
            showLoggedUser();
        }
    };

    const setForm = () => {
        $('.js-invalid-feedback').hide();
        $('.js-recaptcha-error-message').hide();
        showLoggedUser();
    };

    const registerEvents = () => {
        $('.js-login-submit').on('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            const form = $('.js-login-form');
            const url = form.data('action');
            const urlHome = $('.js-logout-url').data('url-home');
            $('.js-invalid-feedback').hide();
            $('.js-recaptcha-error-message').hide();
            var data = form.serialize();
            $.ajax({
                url,
                method: 'post',
                data: data,
                success(response) {
                    if(response.error) {
                        grecaptcha.reset();
                        $('.js-recaptcha-error-message').show();
                        return;
                    }

                    handleResponse(response);
                    window.location = urlHome;
                },
                error(response) {
                    $('.js-invalid-feedback').show();
                },
            });
        });

        $('.js-logout-url').on('click', () => {
            const urlBe = $('.js-logout-url').data('url-be');

            $(document).trigger('logoutClicked');

            $.ajax({
                url: urlBe,
                method: 'GET',
                success() {
                    showLoggedUser();
                },
            });
            setTimeout(() => {
                location.reload();
            }, 2000);
        });

        $('.js-login-url').on('click', () => {
            $(document).trigger('loginClicked');
        });

        $('.js-check-mybf-url').on('click', () => {
            $(document).trigger('myBfClicked');
        });

    };

    function initReCaptchaEvents() {
        const sendButton = $('.js-login-submit');
        const recaptchaResponseInput = $('.js-login-recaptcha');
        window.reCaptchaLoginCallback = (response) => {
            recaptchaResponseInput.val(response);
            sendButton.prop('disabled', false);
            if ($('.js-recaptcha-error-message')) {
                $('.js-recaptcha-error-message').hide();
            }
        };

        sendButton.on('click', (e) => {
            if (!recaptchaResponseInput.val()) {
                sendButton.prop('disabled', true);
                e.preventDefault();
            }
        });
    }

    return {
        init() {
            setForm();
            registerEvents();
            initReCaptchaEvents();
        },
    };
})();

export default (() => {
    $(Login.init);
})();
